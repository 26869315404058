import React from 'react'
import './index.scss'

const accountReminder = () => (
  <div className="duan">
    <h3 id='4fraud'><strong>If you delete your Kisso account:</strong></h3>
    <p>1. After deleting your account, you will never be able to log in to kisso with that account, nor will you be
      able to retrieve any content, data or records from your deleted account.
    </p>
    <p>2. Your cancellation of the account will be deemed as your voluntary and active abandonment of all content,
      information and assets, including personal information, interactive information, scores, props, and other virtual
      assets.
    </p>
    <p>3. After successfully applying for cancellation, you will not be able to cancel the cancellation application to
      the account.
    </p>
    <p className="contact-title">Contact</p>
    <p className="contact-info">
      If you want to delete your account, you should use your Google email and send your Kisso account ID. Please contact us at
      <a href="mailto:ixsxy2023@gmail.com" className="contact-email">ixsxy2023@gmail.com</a>
    </p>
  </div>
)

export default accountReminder
